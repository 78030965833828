import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import TelegramLoginWidget2 from 'components/TelegramLoginWidget2';
import { useAccount } from 'providers/AccountProvider';
import AvatarSample from 'assets/icons/avatar.svg';
import PremiumBadge from 'assets/images/premium.png';
import { eraseCookie } from 'utils/cookies';
import { axios } from 'startup';
import appConfig from 'config';
import './styles.scss';

function MenuProfile() {
    const { t } = useTranslation();
    const { account, setAccount } = useAccount();
    const [popoverActive, setPopoverActive] = useState(false);
    const [premium, setPremium] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (account) {
            const checkPremium = async () => {
                const res = await axios.get('/accounts/checkIfPremium', {
                    params: {
                        accountId: account.account.AccountId,
                    },
                });

                if (res.data?.premiumIf?.premium) {
                    setPremium(true);
                }
            };

            checkPremium();
        }
    }, [account]);

    const avatarSrc =
        account && account.account.avatar
            ? `${appConfig.API_URL}/${account.account.avatar}`
            : AvatarSample;

    return (
        <div>
            {account ? (
                <div className='profile-site-button__row'>
                    <button
                        type='button'
                        className='profile-site-button'
                        onClick={() => {
                            setPopoverActive(!popoverActive);
                        }}
                    >
                        <div
                            className='profile-site-button__menu-profile'
                            onMouseEnter={() => {
                                setPopoverActive(true);
                            }}
                            onMouseLeave={() => {
                                setPopoverActive(false);
                            }}
                        >
                            <div className='profile-site-button__menu-profile__info'>
                                <div className='profile-site-button__menu-profile__info__user'>
                                    {account?.account.Username}
                                </div>
                            </div>
                            <img
                                className='profile-site-button__menu-profile__avatar'
                                src={avatarSrc}
                                alt='Avatar Img'
                            />
                            {premium && (
                                <img
                                    className='profile-site-button__menu-profile__premium'
                                    src={PremiumBadge}
                                    alt='Premium Img'
                                />
                            )}
                            {popoverActive && (
                                <div
                                    className='profile-site-button__menu-profile__popover'
                                    onMouseLeave={() => {
                                        setPopoverActive(false);
                                    }}
                                    onMouseEnter={() => {
                                        setPopoverActive(true);
                                    }}
                                >
                                    <div className='profile-site-button__menu-profile__popover__content'>
                                        <Link
                                            to='/'
                                            className='profile-site-button__menu-profile__popover__content__link'
                                            onClick={() => {
                                                eraseCookie('accessToken');
                                                if (setAccount)
                                                    setAccount(undefined);
                                                navigate('/');
                                            }}
                                        >
                                            {t('general.LOGOUT')}
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </button>
                </div>
            ) : (
                <div className='menu-profile-btn-container'>
                    <TelegramLoginWidget2 />
                </div>
            )}
        </div>
    );
}

export default MenuProfile;
