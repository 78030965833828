import React from 'react';
import {
    CartesianGrid,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { useWallet } from 'providers/WalletProvider';
import { usePortfolioData } from 'hooks/usePortfolioData';

function CustomTooltip({ active, payload }: any) {
    if (active && payload && payload.length > 0) {
        return (
            <div className='chart-customTooltip'>{`$${payload[0].value.toFixed(
                2,
            )}`}</div>
        );
    }

    return null;
}

export function PortfolioChart({ dateRange }: { dateRange: string }) {
    const { wallet } = useWallet();
    const { portfolioHistory } = usePortfolioData(wallet.myWallet, dateRange);

    return (
        <ResponsiveContainer
            width='100%'
            height={650}
            className='portfolio-chart'
        >
            <LineChart data={portfolioHistory}>
                <Tooltip content={<CustomTooltip />} />
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                    dataKey='date'
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                />
                <YAxis
                    stroke='#888888'
                    fontSize={12}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={value => `$${value}`}
                    domain={['auto', 'auto']}
                />
                <Line
                    type='monotone'
                    dataKey='value'
                    fill='currentColor'
                    className='fill-primary'
                    stroke='#e11d48'
                    strokeWidth={3}
                    activeDot={{ r: 8, style: { fill: '#09090b' } }}
                />
            </LineChart>
        </ResponsiveContainer>
    );
}
