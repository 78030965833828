import React from 'react';
import { notification } from 'antd';

import { Button } from 'components/Button';
import { Separator } from 'components/Separator';
import { useSettings } from 'providers/SettingProvider';
import { useAccount } from 'providers/AccountProvider';
import { axios } from 'startup';
import './styles.scss';
import ChainSetting from './chain';
import TokenSetting from './token';

function Settings() {
    const { settings } = useSettings();
    const { account } = useAccount();

    const [selectedChains, setSelectedChains] = React.useState(
        settings.Chains || [],
    );
    const [minimumAmount, setMinimumAmount] = React.useState(
        settings.MinimumAmount.toString() || '0',
    );
    const [blockedTokens, setBlockedTokens] = React.useState(
        settings.BlockedTokens || [],
    );

    const handleSave = () => {
        axios
            .post('/settings/update', {
                userId: account?.account?.AccountId,
                chains: selectedChains,
                minimumAmount: parseFloat(minimumAmount),
                blockedTokens,
            })
            .then(res => {
                if (res.data === 'Success') {
                    notification.success({
                        message: 'Success',
                        description: 'Settings successfully updated!',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Update failed!',
                    });
                }
            });
    };

    const handleCancel = () => {
        setSelectedChains(settings.Chains || []);
        setMinimumAmount(settings.MinimumAmount.toString() || '0');
        setBlockedTokens(settings.BlockedTokens || []);
    };

    return (
        <div className='settings'>
            <div className='settings__wrapper'>
                <div className='settings__wrapper__header'>
                    <h2 className='settings__wrapper__header__title'>
                        Settings
                    </h2>
                    <p className='settings__wrapper__header__description'>
                        Manage your account settings
                    </p>
                </div>

                <Separator />

                <div className='settings__wrapper__content'>
                    <div className='settings__wrapper__content__main'>
                        <ChainSetting
                            selectedChains={selectedChains}
                            setSelectedChains={setSelectedChains}
                        />

                        <Separator />

                        <TokenSetting
                            minimumAmount={minimumAmount}
                            setMinimumAmount={setMinimumAmount}
                            blockedTokens={blockedTokens}
                            setBlockedTokens={setBlockedTokens}
                        />

                        <Separator />

                        <div className='settings__wrapper__content__action'>
                            <Button
                                className='settings__wrapper__content__action__save'
                                onClick={handleSave}
                            >
                                Save Setting
                            </Button>
                            <Button
                                className='settings__wrapper__content__action__cancel'
                                variant='outline'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
