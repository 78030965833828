import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SettingsDTO } from 'DTO/settingsDTO';
import { axios } from '../startup';

interface ISettingsProviderProps {
    children: JSX.Element;
}

interface ISettingsContext {
    settings?: SettingsDTO;
    setSettings?: React.Dispatch<React.SetStateAction<SettingsDTO | undefined>>;
    setFetchTry?: React.Dispatch<React.SetStateAction<boolean>>;
    fetchSettings?: (
        callback: (success: boolean) => void,
        userId: string,
    ) => void;
    fetchTry?: boolean;
}

const SettingsContext = React.createContext<ISettingsContext>({});

export const useSettings = () => useContext(SettingsContext);

function SettingsProvider({ children }: ISettingsProviderProps) {
    const [settings, setSettings] = useState<SettingsDTO>();
    const [fetchTry, setFetchTry] = useState<boolean>(false);

    const value = useMemo<ISettingsContext>(
        () => ({
            settings,
            setSettings,
            setFetchTry,
            fetchSettings: (callback, userId) => {
                axios
                    .post('/settings', {
                        userId,
                    })
                    .then((res: any) => {
                        setSettings(res.data);
                        callback(true);
                    })
                    .catch(() => callback(false))
                    .finally(() => setFetchTry(true));
            },
            fetchTry,
        }),
        [settings, fetchTry],
    );

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}

export default SettingsProvider;
